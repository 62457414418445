import { Link } from 'react-router-dom'
import routes from '../../constants/routes'
import { IProject } from '../../models/IProject'
import usePagination from '../../customHooks/usePagination'

import { Box, Divider, IconButton, List, ListItem, Button } from '@material-ui/core'
import CustomPagination from '../../assets/UIkit/CustomPagination'
import FiButton from '../../assets/UIkit/FiButton'
import { useProjectListQuery, useRemoveProject } from '../../customHooks/request/projectsQuery'
import { paginationLimit } from '../../utils/configs'
import ProjectStatusPillBox from '../PillBoxStatus/ProjectStatusPillBox'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import useDialog from '../../customHooks/useDialog'
import AppDialog from '../../assets/UIkit/AppDialog'
import useRequestAlert from '../../customHooks/useRequestAlert'

type TProjectList = {
  clientId?: string
  asClient?: boolean
}
const limit = paginationLimit

const ProjectList: React.FC<TProjectList> = (props) => {
  const pagination = usePagination()
  const dialog = useDialog<string>()

  const { data: projectQuery, refetch: refetchQuery } = useProjectListQuery({
    filters: { client: props.clientId, page: pagination.page, limit },
  })
  const removeProject = useRemoveProject()
  useRequestAlert(removeProject)

  const setProjectPath = (projectId: string, clientId: string) => {
    if (props.asClient) return `${routes.cpanel.clients.findie}/${props.clientId}/proyectos/detalle/${projectId}`
    return `${routes.cpanel.projects.detail}/${projectId}/${clientId}`
  }

  const removeCurrentProject = () => {
    removeProject.mutate(
      { id: dialog.data ?? '' },
      {
        onSuccess: () => {
          refetchQuery()
          dialog.toggle()
        },
      }
    )
  }

  return (
    <>
      <div className='flex justify-between items-center mt-4'>
        <div className='w-52'>
          {props.asClient && (
            <FiButton
              asLink
              variant='outlined'
              to={`${routes.cpanel.clients.findie}/${props.clientId}/proyectos/nuevo-proyecto`}
              className='mt-4'
            >
              Crear proyecto
            </FiButton>
          )}
        </div>
      </div>

      <p className='subtitle4-bold mt-20'>Activos</p>
      <List className='mt-4 py-0'>
        {projectQuery &&
          projectQuery.data
            .filter((project) => ['not_approved', 'in_progress'].includes(project.project_status) && Boolean(project.client))
            .map((project) => {
              return (
                <div key={project._id} className='py-0  border-b border-gray-300'>
                  <ListItem dense button>
                    <Link className='w-full px-4' to={setProjectPath(project?._id, project?.client?._id)}>
                      <Box display='grid' gridTemplateColumns='40% 25% 1fr' className='gap-4 py-4'>
                        <p className='subtitle4-regular truncate'>{project.brief?.title ? project.brief.title : 'Sin titulo'}</p>
                        <div>{!props.asClient && <p className='subtitle4-regular truncate'>{project.client.companyName}</p>}</div>
                        <div>
                          <ProjectStatusPillBox
                            className='w-36'
                            projectStatus={project.project_status}
                            evaluationStatus={project.evaluation_status}
                            variant='outlined'
                          />
                        </div>
                      </Box>
                    </Link>
                    <Box width='50px'>
                      {(!project.staff.length || !project.staff[0]?.freelancer) && (
                        <IconButton edge='end' onClick={() => dialog.toggle({ dialogData: project._id })}>
                          <FiIcons name='skull' className='svg-soft-gray' />
                        </IconButton>
                      )}
                    </Box>
                  </ListItem>
                </div>
              )
            })}
      </List>

      <p className='subtitle4-bold mt-20'>Históricos</p>
      <List className='mt-4 py-0'>
        {projectQuery &&
          projectQuery.data
            .filter((project: IProject) => ['finished', 'cancelled'].includes(project.project_status))
            .map((project) => {
              return (
                <ListItem dense button key={project._id} className='p-0'>
                  <Divider className='mb-4' />
                  <Link className='w-full' to={setProjectPath(project._id, project.client._id)}>
                    <Box display='grid' gridTemplateColumns='40% 25% 1fr' className='gap-4 py-4 border-t border-gray-300'>
                      <p className='subtitle4-regular truncate'>{project.brief.title}</p>
                      {!props.asClient && <p className='subtitle4-regular truncate'>{project.client.companyName}</p>}
                      <div>
                        <ProjectStatusPillBox
                          className='w-36'
                          projectStatus={project.project_status}
                          evaluationStatus={project.evaluation_status}
                          variant='outlined'
                        />
                      </div>
                    </Box>
                  </Link>
                </ListItem>
              )
            })}
      </List>

      {projectQuery && (
        <div className='mt-6 flex justify-center'>
          <CustomPagination
            pages={projectQuery.metadata.pages}
            page={pagination.page}
            onChange={(e, value) => pagination.handlePage(value)}
            position={'center'}
          />
        </div>
      )}

      <AppDialog open={dialog.isOpen} title='eliminar ' handleClose={dialog.toggle}>
        <span className='subtitle4-medium'>¿Estás seguro que deseas eliminar este proyecto? No podrás revertir esta acción.</span>
        <Divider className='my-3' />

        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button variant='contained' className='mr-2' onClick={() => dialog.toggle()}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' onClick={removeCurrentProject}>
            Aceptar
          </Button>
        </Box>
      </AppDialog>
    </>
  )
}

export default ProjectList

import { FC, useState, useMemo, useEffect } from 'react'
import FiButton from '../../../assets/UIkit/FiButton'
import { IFreelancer } from '../../../models/IFreelancer'
import { Divider, Box } from '@material-ui/core'
import AppDialog from '../../../assets/UIkit/AppDialog'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { useForm } from 'react-hook-form'
import { cleanString, created_at, getTimeStamp } from '../../../utils/helpers'
import { TProjectSections } from '../Project'
import { useCreateOffers } from '../../../customHooks/request/offersQuery'
import { ICreateOffersBody, IOffer } from '../../../models/IOffer'
import { IStaff } from '../../../models/IStaff'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import { useFreelancersFindieQuery } from '../../../customHooks/request/freelancersQuery'
import CandidateCard from './CandidateCard'
import { IProject } from '../../../models/IProject'
import dayjs from 'dayjs'

type TProjectCandidatesSelection = {
  isDialogOpen: boolean
  currentStaff: IStaff | undefined
  offerQuery: IOffer[]
  refetchOffer: (
    options?: (RefetchOptions & RefetchQueryFilters<IOffer[]>) | undefined
  ) => Promise<QueryObserverResult<IOffer[], unknown>>
  refetchProject: (
    options?: (RefetchOptions & RefetchQueryFilters<IProject>) | undefined
  ) => Promise<QueryObserverResult<IProject, unknown>>
  setIsDialogOpen: () => void
}

const ProjectCandidatesSelection: FC<TProjectCandidatesSelection & TProjectSections> = (props) => {
  const form = useForm()
  const { data: project } = props.project
  const briefQuery = project?.brief
  const {
    data: freelancerQuery,
    refetch: refetchFreelancer,
    isLoading,
  } = useFreelancersFindieQuery({
    filters: {
      category: props.currentStaff?.category?._id ?? undefined,
      freelancer_status: 'available',
      isShortResponse: true,
      $or: [{ is_hidden: { $exists: false } }, { is_hidden: false }],
    },
    enabled: false,
  })
  const createOffers = useCreateOffers()
  useRequestAlert(createOffers)
  const [areYouSure, setAreYouSure] = useState(false)
  const [selectedCandidates, setSelectedCandidates] = useState<IFreelancer[]>([])
  const filterWord = cleanString(form.watch('filter_word') ?? '')

  const candidates = useMemo(() => {
    if (!freelancerQuery) return []
    if (!filterWord) return freelancerQuery.data

    return freelancerQuery.data.filter((freelancer: IFreelancer) => {
      const matchName = cleanString(freelancer.name ?? '').includes(filterWord)
      const matchLastName = cleanString(freelancer.lastName ?? '').includes(filterWord)
      const matchEmail = cleanString(freelancer.email ?? '').includes(filterWord)
      const matchDegree = cleanString(freelancer.college_degree ?? '').includes(filterWord)
      return matchName || matchLastName || matchEmail || matchDegree
    })
  }, [freelancerQuery, filterWord])

  const selectCandidate = (candidate: IFreelancer) => {
    const isCandidateSelected = selectedCandidates.some((currentCandidate: IFreelancer) => currentCandidate._id === candidate._id)
    if (isCandidateSelected) {
      const filteredCandidates = selectedCandidates.filter(
        (currentCandidate: IFreelancer) => currentCandidate._id !== candidate._id
      )
      setSelectedCandidates(filteredCandidates)
      return
    }
    setSelectedCandidates([...selectedCandidates, candidate])
  }

  const setProjectDates = (whenStart: string) => {
    const today = dayjs()
    const dispatch: Record<string, { offerExpirationDate: dayjs.Dayjs; startDate: dayjs.Dayjs }> = {
      'Lo antes posible': { offerExpirationDate: today.add(3, 'day'), startDate: today.add(5, 'day') },
      'lo antes posible': { offerExpirationDate: today.add(3, 'day'), startDate: today.add(5, 'day') },
      'As soon as possible': { offerExpirationDate: today.add(3, 'day'), startDate: today.add(5, 'day') },
      'as soon as possible': { offerExpirationDate: today.add(3, 'day'), startDate: today.add(5, 'day') },
      'Dentro de 1 semana': { offerExpirationDate: today.add(5, 'day'), startDate: today.add(7, 'day') },
      'dentro de 1 semana': { offerExpirationDate: today.add(5, 'day'), startDate: today.add(7, 'day') },
      'One week from now': { offerExpirationDate: today.add(5, 'day'), startDate: today.add(7, 'day') },
      'one week from now': { offerExpirationDate: today.add(5, 'day'), startDate: today.add(7, 'day') },
      'Within 1 week': { offerExpirationDate: today.add(5, 'day'), startDate: today.add(7, 'day') },
      'within 1 week': { offerExpirationDate: today.add(5, 'day'), startDate: today.add(7, 'day') },
      'Tengo tiempo': { offerExpirationDate: today.add(7, 'day'), startDate: today.add(9, 'day') },
      'tengo tiempo': { offerExpirationDate: today.add(7, 'day'), startDate: today.add(9, 'day') },
      'I have time': { offerExpirationDate: today.add(3, 'day'), startDate: today.add(9, 'day') },
      'i have time': { offerExpirationDate: today.add(3, 'day'), startDate: today.add(9, 'day') },
    }
    return dispatch[whenStart]
  }

  const createCandidates = () => {
    const body: ICreateOffersBody = {
      created_at,
      project: props.projectId,
      client: props.clientId,
      brief: briefQuery?._id ?? '',
      staff: props.currentStaff?._id ?? '',
      candidates: selectedCandidates,
      offer_expiration_date: getTimeStamp(
        setProjectDates(project?.brief.when_start ?? '').offerExpirationDate.format('YYYY-MM-DD')
      ),
      start_date: getTimeStamp(setProjectDates(project?.brief.when_start ?? '').startDate.format('YYYY-MM-DD')),
      has_offers: project?.has_offers ?? false,
    }
    console.log('body', body)

    createOffers.mutate(
      { body },
      {
        onSuccess: async () => {
          await props.refetchOffer()
          await props.refetchProject()
          props.setIsDialogOpen()
          setAreYouSure(false)
          setSelectedCandidates([])
          form.setValue('filter_word', '')
        },
      }
    )
  }

  useEffect(() => {
    props.isDialogOpen && refetchFreelancer()
  }, [props.isDialogOpen])

  return (
    <div>
      <InputField
        name='filter_word'
        label='Filtra por nombre, apellido, correo, especialidad'
        inputProps={{ className: 'w-full mr-2 border border-gray-300', placeholder: 'Filtrar por nombre' }}
        options={{ required: 'Este campo es requerido' }}
        form={form}
      />
      <Box height='65vh' width='85vw' className='grid grid-cols-12 gap-4 overflow-auto px-1 mt-4'>
        {!isLoading && (
          <>
            {candidates.length === 0 ? (
              <h4 className='col-span-12 text-center self-center'>No hay candidatos disponibles...</h4>
            ) : (
              <>
                {candidates
                  .filter((freelancer: IFreelancer) => Boolean(freelancer?.plan?.fee))
                  .filter((freelancer: IFreelancer) => {
                    const isSelected = props.offerQuery.some((offer: IOffer) => offer.freelancer._id === freelancer._id)
                    return !isSelected
                  })
                  .map((freelancer: IFreelancer) => {
                    const isSelected = selectedCandidates.some((candidate: IFreelancer) => candidate._id === freelancer._id)
                    return (
                      <div key={freelancer._id} className='xl:col-span-3 col-span-4'>
                        <CandidateCard {...freelancer} selectCandidate={selectCandidate} isSelected={isSelected} />
                      </div>
                    )
                  })}
              </>
            )}
          </>
        )}
      </Box>

      <div className='flex justify-end mt-4 pt-2 border-t border-gray-300'>
        <Divider />
        <FiButton variant='outlined' theme='secondary' className='mr-4' onClick={props.setIsDialogOpen}>
          Cancelar
        </FiButton>
        <FiButton
          variant='contained'
          disabled={selectedCandidates.length === 0}
          theme='secondary'
          onClick={() => setAreYouSure(true)}
        >
          Aceptar
        </FiButton>
      </div>

      <AppDialog open={areYouSure} title='Candidatos seleccionados'>
        <p className='subtitle5-medium mb-2'>¿Estas seguro seleccionar los siguientes candidatos?</p>
        {selectedCandidates.map((freelancer: IFreelancer) => {
          return (
            <Box key={freelancer._id} className='mt-1.5' display='grid' gridTemplateColumns='2.8rem 1fr' alignItems='start'>
              <img className='mt-2' style={{ width: 33, height: 32 }} src={freelancer.avatar.url} />
              <div className='mt-1'>
                <p className='subtitle5-medium'>{freelancer.name}</p>
                <p className='body2-regular -mt-1'>{freelancer.college_degree}</p>
              </div>
            </Box>
          )
        })}

        <div className='flex justify-end mt-4 pt-2 border-t border-gray-300'>
          <FiButton variant='outlined' theme='secondary' className='mr-4' onClick={() => setAreYouSure(false)}>
            Cancelar
          </FiButton>
          <FiButton variant='contained' theme='secondary' onClick={createCandidates}>
            Seleccionar candidatos
          </FiButton>
        </div>
      </AppDialog>
    </div>
  )
}

export default ProjectCandidatesSelection

import { useEffect, FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { personalFields } from '../../constants/Cpanel/FreelancersConstant'
import { IFreelancer } from '../../models/IFreelancer'

import InputField from '../../assets/UIkit/Forms/InputField'
import dayjs from 'dayjs'

type TFreelancerFormPersonalData = {
  form: UseFormReturn<any>
  freelancer?: IFreelancer | undefined
  isDisabledFields?: boolean
  variant?: 'default' | 'outlined'
  asCpanel?: boolean
  asFreelancerFindie?: boolean
  asPostulation?: boolean
}

const FreelancerFormPersonalData: FC<TFreelancerFormPersonalData> = (props) => {
  const { variant = 'outlined' } = props

  const isCreatedAtRender = () => {
    if (props.asFreelancerFindie || props.asPostulation) return !props.form.watch('createdAt')
    return props.asCpanel
  }

  useEffect(() => {
    if (!props.freelancer) return
    Object.entries(props.freelancer).forEach(([key, value]) => {
      if (!personalFields.includes(key)) return
      if (key === 'address') {
        props.form.setValue('address.country', value.country)
        props.form.setValue('address.city', value.city)
        return
      }
      if (['createdAt', 'birthdate'].includes(key)) return props.form.setValue(key, dayjs(value).format('YYYY-MM-DD'))
      props.form.setValue(key, value)
    })
  }, [props.freelancer])

  return (
    <div className='grid grid-cols-12 mt-4 gap-4'>
      {props.form.watch('createdAt') && (props.asFreelancerFindie || props.asPostulation) && (
        <p className='col-span-12 subtitle1-medium'>
          Fecha de ingreso: {dayjs(props.form.watch('createdAt')).format('DD/MM/YYYY')}
        </p>
      )}
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='name'
          labelClassName='text-gray-500'
          variant={variant}
          label='Nombre'
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu nombre' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='lastName'
          labelClassName='text-gray-500'
          variant={variant}
          label='Apellido'
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu apellido' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-12'>
        <InputField
          name='email'
          labelClassName='text-gray-500'
          variant={variant}
          label='Email'
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu email', type: 'email' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='phone'
          labelClassName='text-gray-500'
          variant={variant}
          label='Teléfono'
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: 'Ingresa tu teléfono',
            type: 'number',
          }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='birthdate'
          labelClassName='text-gray-500'
          variant={variant}
          label='Fecha de nacimiento'
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, type: 'date' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      {isCreatedAtRender() && (
        <div className='md:col-span-4 col-span-6'>
          <InputField
            name='createdAt'
            labelClassName='text-gray-500'
            variant={variant}
            label='Fecha de ingreso'
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              type: 'date',
              min: dayjs().format('YYYY-MM-DD'),
            }}
            options={{ required: 'Este campo es requerido' }}
            form={props.form}
          />
        </div>
      )}
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='address.country'
          labelClassName='text-gray-500'
          variant={variant}
          label='País'
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu país' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='address.city'
          labelClassName='text-gray-500'
          variant={variant}
          label='Ciudad'
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu ciudad' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='nationality'
          labelClassName='text-gray-500'
          variant={variant}
          label='Nacionalidad'
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu nacionalidad' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='portfolio.name'
          className='hidden'
          inputProps={{
            value: 'finde-portfolio',
            disabled: props.isDisabledFields,
          }}
          form={props.form}
        />
        <InputField
          name='portfolio.url'
          labelClassName='text-gray-500'
          variant={variant}
          label='URL del Portafolio'
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: 'Ingresa la URL de tu portafolio',
          }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
    </div>
  )
}

export default FreelancerFormPersonalData
